import React from 'react';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import Advert from '../components/Advert';
import './Home.css';

const fetchUsers = () => {

     return axios(
     {
          url: 'https://api.penewo.com/api/latest-users',
          method: 'get'
     }).then(response => {
          console.log(response);
          return response.data;
     })
};

const fetchRummageTerms = () => {

     return axios(
     {
          url: 'https://api.penewo.com/api/rummagebox-terms',
          method: 'get'
     }).then(response => {
          console.log(response);
          return response.data;
     })
};


const Home: React.FC = () => {
     var state = {
          test: 'TestValue',
          latestUsers: [],
          loading: true
     }

     const [users, setUsers] = React.useState([]);
     const [terms, setTerms] = React.useState([]);


     React.useEffect(() => {

          fetchUsers().then(data => setUsers(data));
          fetchRummageTerms().then(data => setTerms(data));

     }, []);

     function RenderFeaturedUploads(userId:string, uploads:any) {
          let imageOutput = [];
          let imageId = 0;

          for (let featuredUpload of uploads)
          {
               console.log(featuredUpload);
               imageOutput.push(
                    <a href={"/profile/"+userId+"/uploads/"+imageId}><div style={{display: 'inline-block', width: 51, height: 51, border: '1px solid #FFF', backgroundPosition: 'center center', backgroundImage: "url('"+featuredUpload["path"]+"')", backgroundSize: 'cover'}}></div></a>
               )

               imageId++;
          }

          return imageOutput;
     }

     var itemCount = 0;

     return (
          <IonPage style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
               <IonContent>
                    <div style={{marginTop:80, marginBottom: 20, display: 'flex', flexWrap: 'nowrap', position: 'relative', overflowX: 'auto'}}>
                         {
                              terms.map(term => {

                                   return (
                                        <div key={term["id"]} style={{width: 87, flex: '0 0 auto', float: 'left', whiteSpace: 'nowrap', textAlign: 'center', fontSize: 11}}>
                                             <a href={"/"+term["url"]} style={{color: '#333', padding: 0, textDecoration: 'none'}}>
                                                  <img src={term['image']} style={{width: 82, marginBottom: 5, display: 'block'}} />
                                                  <div>{term['name']}</div>
                                                  <div style={{fontSize: 9}}>{term['type']}</div>
                                             </a>
                                        </div>
                                   );
                              })
                         }
                    </div>

                    <div style={{fontSize: 13, paddingLeft: 3}}>Latest Members:</div>
                    <div>

                         {
                              users.map(userInfo => {
                                   itemCount++;
                                   console.log(itemCount);
                                   if(Number.isInteger(itemCount/3))
                                   {
                                        return (
                                             <div>
                                                  <div key={userInfo["id"]} style={{marginBottom: 40}}>
                                                       <div style={{padding: 0, marginBottom: 0, height: 190, position: 'relative', width: '100%'}}>
                                                            <a href={"/profile/"+userInfo["id"]} style={{padding: 0, textDecoration: 'none'}}>
                                                                 <div style={{color: '#ffffff', fontSize: 15, marginTop: 5, marginBottom: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 6, backgroundColor: userInfo["micro_color"], borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF', textAlign: 'center'}}><div style={{color: '#ffffff', textAlign: 'center', fontWeight: 'bold'}}>{userInfo['name']}</div></div>
                                                                 <div style={{margin:0, padding: 0, overflow: 'visible', borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF'}}>
                                                                      <div style={{float: 'left'}}>
                                                                           <img src={userInfo['profile_picture']} style={{width:110, marginRight: 10, marginBottom: -6}} />
                                                                      </div>
                                                                      <div style={{backgroundColor: '#859fc4', height: 110, marginLeft: 110, position: 'relative'}}>
                                                                           <div style={{overflow: 'hidden', paddingRight: 10, paddingTop: 8}}>
                                                                                <div style={{color: '#FFFFFF', fontSize: 16, marginRight: 10, fontStyle: 'italic', height: 56, letterSpacing: 1, whiteSpace: 'pre-line', overflow: 'hidden'}}>{userInfo['description']}</div>

                                                                           </div>
                                                                           <div style={{ position: 'absolute', bottom: 5, fontSize: 13, color: '#FFFFFF', paddingLeft: 10, paddingTop: 5}}>
                                                                                <div style={{color: '#FFFFFF', marginBottom: 2}}>{userInfo['country_name']}, {userInfo['city_name']}</div>
                                                                                <div style={{color: '#FFFFFF'}}>{userInfo['keyword']['name']}</div>
                                                                           </div>
                                                                      </div>

                                                                 </div>
                                                            </a>
                                                            <div style={{backgroundColor: '#7386ae', height: 51, padding: 0, whiteSpace: 'nowrap', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#CCCCCC'}}>
                                                                 {RenderFeaturedUploads(userInfo["id"], userInfo["uploads"])}
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <Advert />
                                             </div>
                                        );
                                   }
                                   else
                                   {
                                        return (
                                             <div key={userInfo["id"]} style={{marginBottom: 40}}>
                                                  <div style={{padding: 0, marginBottom: 0, height: 190, position: 'relative', width: '100%'}}>
                                                       <a href={"/profile/"+userInfo["id"]} style={{padding: 0, textDecoration: 'none'}}>
                                                            <div style={{color: '#ffffff', fontSize: 15, marginTop: 5, marginBottom: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 6, backgroundColor: userInfo["micro_color"], borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF', textAlign: 'center'}}><div style={{color: '#ffffff', textAlign: 'center', fontWeight: 'bold'}}>{userInfo['name']}</div></div>
                                                            <div style={{margin:0, padding: 0, overflow: 'visible', borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF'}}>
                                                                 <div style={{float: 'left'}}>
                                                                      <img src={userInfo['profile_picture']} style={{width:110, marginRight: 10, marginBottom: -6}} />
                                                                 </div>
                                                                 <div style={{backgroundColor: '#859fc4', height: 110, marginLeft: 110, position: 'relative'}}>
                                                                      <div style={{overflow: 'hidden', paddingRight: 10, paddingTop: 8}}>
                                                                           <div style={{color: '#FFFFFF', fontSize: 16, marginRight: 10, fontStyle: 'italic', height: 56, letterSpacing: 1, whiteSpace: 'pre-line', overflow: 'hidden'}}>{userInfo['description']}</div>

                                                                      </div>
                                                                      <div style={{ position: 'absolute', bottom: 5, fontSize: 13, color: '#FFFFFF', paddingLeft: 10, paddingTop: 5}}>
                                                                           <div style={{color: '#FFFFFF', marginBottom: 2}}>{userInfo['country_name']}, {userInfo['city_name']}</div>
                                                                           <div style={{color: '#FFFFFF'}}>{userInfo['keyword']['name']}</div>
                                                                      </div>
                                                                 </div>

                                                            </div>
                                                       </a>
                                                       <div style={{backgroundColor: '#7386ae', height: 51, padding: 0, whiteSpace: 'nowrap', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#CCCCCC'}}>
                                                            {RenderFeaturedUploads(userInfo["id"], userInfo["uploads"])}
                                                       </div>
                                                  </div>
                                             </div>
                                        );
                                   }
                              })
                         }
                         <Advert />
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default Home;
