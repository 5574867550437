import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonTextarea, IonList, IonItem, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditNamecard.css';

const EditNamecard: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [profilePicture, setProfilePicture] = React.useState('');
     const [microColor, setMicroColor] = React.useState('');
     const [microEndColor, setMicroEndColor] = React.useState('');
     const [name, setName] = React.useState('');
     const [city, setCity] = React.useState('');
     const [country, setCountry] = React.useState('');
     const [position, setPosition] = React.useState('');
     const [intro, setIntro] = React.useState('');

     const colorArray = new Array();
     colorArray.push({"start": "#b6656e", "color": "#722029"});
     colorArray.push({"start": "#bc7eac", "color": "#6c305d"});
     colorArray.push({"start": "#ca699d", "color": "#89265a"});
     colorArray.push({"start": "#d5bc97", "color": "#917651"});
     colorArray.push({"start": "#9f896a", "color": "#5b4425"});
     colorArray.push({"start": "#57866e", "color": "#16432b"});
     colorArray.push({"start": "#8eaac3", "color": "#4b657e"});
     colorArray.push({"start": "#7f95bb", "color": "#33486c"});
     colorArray.push({"start": "#56a5c0", "color": "#125f7b"});
     colorArray.push({"start": "#868787", "color": "#424142"});


     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     console.log(privateUserId);

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setProfilePicture(data[0]["profile_picture"]);
               setMicroColor(data[0]["micro_color"]);
               setMicroEndColor(data[0]["micro_color_end"]);
               setName(data[0]["micro_name"]);
               setCity(data[0]["micro_city"]);
               setCountry(data[0]["micro_country"]);
               setPosition(data[0]["micro_position"]);
               setIntro(data[0]["micro_content"]);
          });
     }, []);

     function saveData()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('micro_name', name);
               bodyFormData.append('micro_city', city);
               bodyFormData.append('micro_country', country);
               bodyFormData.append('micro_position', position);
               bodyFormData.append('micro_content', intro);
               bodyFormData.append('micro_color', microColor);
               bodyFormData.append('micro_color_end', microEndColor);

               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/namecard',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Namecard</h2>

                         <div style={{marginBottom: 15, marginLeft: -15, marginRight: -15}}>
                              <div style={{position: 'relative', width: '100%'}}>
                                   <div style={{overflow: 'visible', borderBottom: '1px solid #FFF', height: 140}}>
                                        <div style={{display: 'inline-block', verticalAlign: 'top', float: 'left'}}>
                                             <img src={profilePicture} style={{height: 140}} />
                                        </div>
                                        <div style={{display: 'inline-block', verticalAlign: 'top', marginLeft: -5, background: 'linear-gradient(to right, '+microColor+', '+microEndColor+')', height: 140, position: 'absolute', left: 140, right: 0}}>
                                             <div style={{paddingLeft: 12, paddingTop: 5, paddingBottom: 5}}>
                                             <h2 style={{color: '#FFF', fontSize: 16, marginTop: 0, marginBottom: 0}}>{name}</h2>
                                             <div style={{color: '#FFF', fontSize: 13, whiteSpace: 'pre-line', maxHeight: 30, overflow: 'hidden'}}>
                                                  <div>{position}</div>
                                                  </div>
                                             </div>
                                             <div style={{position:'absolute', bottom: 0, paddingLeft: 12, color: '#FFF', fontSize: 16, paddingBottom: 5}}>
                                                  <span>{city}</span><br /><span>{country}</span>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div style={{borderTop: '2px solid #FFF', height: 50, color: '#FFF', paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8, fontStyle: 'italic', background: '#8c8d8f'}}>
                                   <div style={{height: 35, fontSize: 14, overflow: 'hidden'}}>{intro}</div>
                              </div>
                         </div>

                         {
                              colorArray.map(colorItem => {
                                   if(colorItem["start"] !== "")
                                   {
                                        return (
                                             <div style={{display: 'inline-block', width: '20%', height: 40, textAlign: 'center', marginBottom:20}}>
                                                  <div onClick={() => {setMicroColor(colorItem["start"]); setMicroEndColor(colorItem["color"]);}} style={{background: colorItem["start"], display: 'inline-block', width: 50, height: 50, padding: 8, verticalAlign: 'top'}}>
                                                       {(colorItem["start"] === microColor) ? <img src="./assets/icon/icon-check.png" style={{maxHeight: '100%'}} /> : "" }
                                                  </div>
                                             </div>
                                        )
                                   }
                              })
                         }

                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20, paddingRight: 15}}>

                              <IonList>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Name</IonLabel>
                                        <IonInput value={name} onIonChange={(e) => setName((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">City</IonLabel>
                                        <IonInput value={city}  onIonChange={(e) => setCity((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Country</IonLabel>
                                        <IonInput value={country}  onIonChange={(e) => setCountry((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 5}}>
                                        <IonLabel position="stacked">Job Position</IonLabel>
                                             <IonTextarea rows={4} value={position} onIonChange={(e) => setPosition((e.target as HTMLInputElement).value)}></IonTextarea>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 5}}>
                                        <IonLabel position="stacked">Intro</IonLabel>
                                             <IonTextarea rows={4} value={intro} onIonChange={(e) => setIntro((e.target as HTMLInputElement).value)}></IonTextarea>
                                   </IonItem>
                              </IonList>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => saveData()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditNamecard;
