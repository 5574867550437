import React from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api'
import { IonContent, IonInput, IonLabel, IonTextarea, IonSearchbar, IonModal, IonButtons, IonButton, IonList, IonItem, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditMap.css';

const EditMap: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);

     const [dataLoaded, setDataLoaded ] = React.useState(false);

     const [lat, setLat] = React.useState('25.7617');
     const [lng, setLng] = React.useState('-80.1918');
     const [zoom, setZoom] = React.useState(13);

     const [center, setCenter] = React.useState({lat: 25.7617, lng: -80.1918});


     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     const mapContainerStyle = { height: "100vh", width: "100vw" };

     console.log(privateUserId);

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               var coordinates = data[0]["coordinates"].split(',');
               setLat(coordinates[0]);
               setLng(coordinates[1]);
               setZoom(parseInt(coordinates[2]));

               setCenter({lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])});

               setDataLoaded(true);
          });
     }, []);

     function updateCoordinates(e:any)
     {
          setLat(e.latLng.lat());
          setLng(e.latLng.lng());
     }

     function saveData()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('lat', JSON.stringify(lat));
               bodyFormData.append('lng', JSON.stringify(lng));
               bodyFormData.append('zoom', JSON.stringify(zoom));


               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/map',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 64}}>
                         {    (dataLoaded) ?
                              <LoadScript id="script-loader" googleMapsApiKey="AIzaSyAeEpA4-JrWyCG9R_gAYPfzr_GDEVtCEMs" >
                                   <GoogleMap id='example-map' mapContainerStyle={mapContainerStyle} zoom={zoom} center={center}>
                                        <Marker position={center} draggable={true} onDragEnd={(e) => updateCoordinates(e as any)} />
                                   </GoogleMap>
                              </LoadScript>
                              :
                              ""
                         }
                    </div>
                    <div style={{position:'fixed', bottom: 0, left: 0, right: 0}}>
                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => saveData()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditMap;
