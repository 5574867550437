import React from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonList, IonItem, IonLabel, IonFooter } from '@ionic/react';
import './SearchResult.css';

interface SearchResultPageProps extends RouteComponentProps<{
  term: string;
  location: string;
}> {}

const SearchResult: React.FC<SearchResultPageProps> = ({match}) => {

     const searchTerm = match.params.term;
     const searchLocation = match.params.location;

     const [profiles, setProfiles] = React.useState([]);
     const [termData, setTermData] = React.useState({name: ''});

     const fetchTermData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-term-data/'+searchTerm,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     const fetchSearchResults = () => {

          let bodyFormData = new FormData();
          bodyFormData.append('term', searchTerm);
          bodyFormData.append('location', searchLocation);

          return axios(
          {
               url: 'https://api.penewo.com/api/return-search-results',
               method: 'post',
               data: bodyFormData
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchSearchResults().then(data => setProfiles(data));
          fetchTermData().then(data => setTermData(data));
     }, []);

     function RenderFeaturedUploads(userId:string, uploads:any) {
          let imageOutput = [];
          let imageId = 0;

          for (let featuredUpload of uploads)
          {
               console.log(featuredUpload);
               imageOutput.push(
                    <a href={"/profile/"+userId+"/uploads/"+imageId}><div style={{display: 'inline-block', width: 51, height: 51, border: '1px solid #FFF', backgroundPosition: 'center center', backgroundImage: "url('"+featuredUpload["path"]+"')", backgroundSize: 'cover'}}></div></a>
               )

               imageId++;
          }

          return imageOutput;
     }

     return (
          <IonPage>
               <IonContent>

               <div style={{marginTop: 80}}>
                    <div style={{marginTop: 20, marginBottom: 10, paddingLeft: 15}}>
                         <h2 style={{fontSize: 14, fontWeight: 'bold', margin: 0}}>Search Results</h2>
                         <h3 style={{fontSize: 14, fontWeight: 'normal', margin:0}}> - {termData.name}</h3>
                    </div>

                    {
                         profiles.map(userInfo => {

                              return (
                                   <div key={userInfo["id"]} style={{marginBottom: 30}}>
                                        <div style={{padding: 0, marginBottom: 0, height: 190, position: 'relative', width: '100%'}}>
                                             <a href={"/profile/"+userInfo["id"]} style={{padding: 0, textDecoration: 'none'}}>
                                                  <div style={{color: '#ffffff', fontSize: 15, marginTop: 5, marginBottom: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 6, backgroundColor: userInfo["micro_color"], borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF', textAlign: 'center'}}><div style={{color: '#ffffff', textAlign: 'center', fontWeight: 'bold'}}>{userInfo['name']}</div></div>
                                                  <div style={{margin:0, padding: 0, overflow: 'visible', borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF'}}>
                                                       <div style={{float: 'left'}}>
                                                            <img src={userInfo['profile_picture']} style={{width:110, marginRight: 10, marginBottom: -6}} />
                                                       </div>
                                                       <div style={{backgroundColor: '#859fc4', height: 110, marginLeft: 110, position: 'relative'}}>
                                                            <div style={{overflow: 'hidden', paddingRight: 10, paddingTop: 8}}>
                                                                 <div style={{color: '#FFFFFF', fontSize: 16, marginRight: 10, fontStyle: 'italic', height: 56, letterSpacing: 1, overflow: 'hidden'}}>{userInfo['description']}</div>

                                                            </div>
                                                            <div style={{ position: 'absolute', bottom: 5, fontSize: 13, color: '#FFFFFF', paddingLeft: 10, paddingTop: 5}}>
                                                                 <div style={{color: '#FFFFFF', marginBottom: 2}}>{userInfo['country_name']}, {userInfo['city_name']}</div>
                                                                 <div style={{color: '#FFFFFF'}}>{userInfo['keyword']['name']}</div>
                                                            </div>
                                                       </div>

                                                  </div>
                                             </a>
                                             <div style={{backgroundColor: '#7386ae', height: 51, padding: 0, whiteSpace: 'nowrap', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#CCCCCC'}}>
                                                  {RenderFeaturedUploads(userInfo["profiles_id"], userInfo["uploads"])}
                                             </div>
                                        </div>
                                   </div>
                              );
                         })
                    }
               </div>

               </IonContent>
          </IonPage>
     );
};

export default SearchResult;
