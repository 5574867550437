import React from 'react';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonActionSheet, IonList, IonItem, IonAvatar, IonCheckbox, IonLabel, IonToggle } from '@ionic/react';
import './NewsFeed.css';

const NewsFeed: React.FC = () => {

     const [userData, setUserData] = React.useState([]);

     const[newsFeedFilter_keywordsOn, setNewsFeedFilter_keywordsOn] = React.useState("1");
     const[newsFeedFilter_friendsOn, setNewsFeedFilter_friendsOn] = React.useState("1");
     const[newsFeedFilter_followsOn, setNewsFeedFilter_followsOn] = React.useState("1");

     const[newsFeedFilter_keywords, setNewsFeedFilter_keywords] = React.useState([]);
     const[newsFeedFilter_friends, setNewsFeedFilter_friends] : any = React.useState("all");
     const[newsFeedFilter_friendsInterests, setNewsFeedFilter_friendsInterests] = React.useState([]);
     const[newsFeedFilter_follows, setNewsFeedFilter_follows] : any = React.useState("all");


     const [ showActionSheet, setShowActionSheet ] = React.useState(false);
     const [ showModalKeywordSuggestions, setShowModalKeywordSuggestions ] = React.useState(false);
     const [ showModalKeywords, setShowModalKeywords ] = React.useState(false);
     const [ showModalFriends, setShowModalFriends ] = React.useState(false);
     const [ showModalFollows, setShowModalFollows ] = React.useState(false);

     const [userKeywords, setUserKeywords] = React.useState([]);
     const [userFriends, setUserFriends] = React.useState([]);
     const [userFollows, setUserFollows] = React.useState([]);

     const [newsFeedItems, setNewsFeedItems] = React.useState([]);

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     console.log(privateUserId);

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data[0]);
               return response.data[0];
          })
     };

     const fetchKeywordsData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-keywords-raw/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     const fetchPartnerData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-partners/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     const fetchFollowData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-follows/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {

          console.log(privateUserId);

          fetchUserData().then(data => {
               setUserData(data);

               if(data["active_newsfeed"] !== "")
               {
                    console.log(data["active_newsfeed"]);
                    let activeNewsfeed = JSON.parse(data["active_newsfeed"]);
                    console.log(activeNewsfeed);

                    setNewsFeedFilter_keywordsOn(activeNewsfeed["keywordsOn"]);
                    setNewsFeedFilter_friendsOn(activeNewsfeed["friendsOn"]);
                    setNewsFeedFilter_followsOn(activeNewsfeed["followsOn"]);

                    setNewsFeedFilter_keywords(activeNewsfeed["keywords"]);
                    setNewsFeedFilter_friends(activeNewsfeed["friends"]);
                    setNewsFeedFilter_friendsInterests(activeNewsfeed["friendsInterests"]);
                    setNewsFeedFilter_follows(activeNewsfeed["follows"]);

                    console.log(
                         "?privateUserId="+JSON.stringify(privateUserId)
                         +"&keywordsOn="+JSON.stringify(activeNewsfeed["keywordsOn"])
                         +"&friendsOn="+JSON.stringify(activeNewsfeed["friendsOn"])
                         +"&followsOn="+JSON.stringify(activeNewsfeed["followsOn"])
                         +"&keywords="+JSON.stringify(activeNewsfeed["keywords"])
                         +"&friends="+JSON.stringify(activeNewsfeed["friends"])
                         +"&friendsInterests="+JSON.stringify(activeNewsfeed["friendsInterests"])
                         +"&follows="+JSON.stringify(activeNewsfeed["follows"])
                    );

                    let bodyFormData = new FormData();
                    bodyFormData.append('privateUserId', JSON.stringify(privateUserId));
                    bodyFormData.append('keywordsOn', JSON.stringify(activeNewsfeed["keywordsOn"]));
                    bodyFormData.append('friendsOn', JSON.stringify(activeNewsfeed["friendsOn"]));
                    bodyFormData.append('followsOn', JSON.stringify(activeNewsfeed["followsOn"]));
                    bodyFormData.append('keywords', JSON.stringify(activeNewsfeed["keywords"]));
                    bodyFormData.append('friends', JSON.stringify(activeNewsfeed["friends"]));
                    bodyFormData.append('friendsInterests', JSON.stringify(activeNewsfeed["friendsInterests"]));
                    bodyFormData.append('follows', JSON.stringify(activeNewsfeed["follows"]));

                    return axios(
                    {
                         url: 'https://api.penewo.com/api/return-news-feed-items',
                         method: 'post',
                         data: bodyFormData,
                         headers: {'Content-Type': 'multipart/form-data' }
                    }).then(response => {
                         if(response.data.errorCode === 1)
                         {
                              console.log(response.data.uploads);

                              // let newsFeedData = newsFeedItems;

                              setNewsFeedItems(response.data.uploads);
                         }
                         else
                         {
                              setNewsFeedItems([]);
                         }
                    });

               }
          });

          fetchKeywordsData().then(data => setUserKeywords(data));
          fetchPartnerData().then(data => setUserFriends(data));
          fetchFollowData().then(data => setUserFollows(data));
     }, []);

     function checkKeywordActive(tid: any)
     {
          let check = false;


               if(newsFeedFilter_keywords.length > 0)
               {
                    for(var i=0;i<newsFeedFilter_keywords.length;i++)
                    {
                         if(newsFeedFilter_keywords[i] == tid)
                         {
                              check = true;
                         }
                    }
               }


          return check;
     }

     let newsFeedFilter_keywords_temp = new Array();

     function setKeywordActiveState(tid: any, isSet: any)
     {
          console.log(isSet);
          if(isSet)
          {
               console.log(newsFeedFilter_keywords);
               newsFeedFilter_keywords_temp = newsFeedFilter_keywords;

               newsFeedFilter_keywords_temp[newsFeedFilter_keywords_temp.length] = JSON.stringify(tid);
               console.log(newsFeedFilter_keywords_temp);
               //setNewsFeedFilter_keywords(newsFeedFilter_keywords_temp);
          }
          else
          {
               console.log(newsFeedFilter_keywords);

               newsFeedFilter_keywords_temp = newsFeedFilter_keywords;

               for(var i=0;i<newsFeedFilter_keywords_temp.length;i++)
               {
                    if(newsFeedFilter_keywords_temp[i] == tid)
                    {
                         newsFeedFilter_keywords_temp.splice(i, 1);
                    }
               }

               //setNewsFeedFilter_keywords(newsFeedFilter_keywords_temp);
          }
     }

     function updateKeywords()
     {
          console.log(newsFeedFilter_keywords);

          setShowModalKeywords(false);

          let bodyFormData = new FormData();
          bodyFormData.append('privateUserId', JSON.stringify(privateUserId));
          bodyFormData.append('keywordsOn', JSON.stringify(newsFeedFilter_keywordsOn));
          bodyFormData.append('friendsOn', JSON.stringify(newsFeedFilter_friendsOn));
          bodyFormData.append('followsOn', JSON.stringify(newsFeedFilter_followsOn));
          bodyFormData.append('keywords', JSON.stringify(newsFeedFilter_keywords));
          bodyFormData.append('friends', JSON.stringify(newsFeedFilter_friends));
          bodyFormData.append('friendsInterests', JSON.stringify(newsFeedFilter_friendsInterests));
          bodyFormData.append('follows', JSON.stringify(newsFeedFilter_follows));

          return axios(
          {
               url: 'https://api.penewo.com/api/return-news-feed-items',
               method: 'post',
               data: bodyFormData,
               headers: {'Content-Type': 'multipart/form-data' }
          }).then(response => {
               console.log(response);
               console.log(response.data.uploads);

               // let newsFeedData = newsFeedItems;

               setNewsFeedItems(response.data.uploads);

               return axios(
               {
                    url: 'https://api.penewo.com/api/return-user-data/'+userId,
                    method: 'get'
               }).then(response => {
                    console.log(response.data[0]);
                    let data = response.data[0];

                    console.log(data["active_newsfeed"]);
                    let activeNewsfeed = JSON.parse(data["active_newsfeed"]);
                    console.log(activeNewsfeed);

                    setNewsFeedFilter_keywordsOn(activeNewsfeed["keywordsOn"]);
                    setNewsFeedFilter_friendsOn(activeNewsfeed["friendsOn"]);
                    setNewsFeedFilter_followsOn(activeNewsfeed["followsOn"]);

                    setNewsFeedFilter_keywords(activeNewsfeed["keywords"]);
                    setNewsFeedFilter_friends(activeNewsfeed["friends"]);
                    setNewsFeedFilter_friendsInterests(activeNewsfeed["friendsInterests"]);
                    setNewsFeedFilter_follows(activeNewsfeed["follows"]);
               })
          });
     }

     function checkFriendActive(id: any)
     {
          let check = false;

          if(newsFeedFilter_friends === "all")
          {
               check = true;
          }
          else
          {
               if(newsFeedFilter_friends.length > 0)
               {
                    for(var i=0;i<newsFeedFilter_friends.length;i++)
                    {
                         if(newsFeedFilter_friends[i] === id)
                         {
                              check = true;
                         }
                    }
               }
          }

          return check;
     }

     function checkFollowActive(id: any)
     {
          let check = false;

          if(newsFeedFilter_follows === "all")
          {
               check = true;
          }
          else
          {
               if(newsFeedFilter_follows.length > 0)
               {
                    for(var i=0;i<newsFeedFilter_follows.length;i++)
                    {
                         if(newsFeedFilter_follows[i] === id)
                         {
                              check = true;
                         }
                    }
               }
          }

          return check;
     }

     let keywordActiveTemp:any;
     let friendsActiveTemp:any;
     let followsActiveTemp:any;

     function updateNewsFeedFilters()
     {
          let bodyFormData = new FormData();
          bodyFormData.append('privateUserId', JSON.stringify(privateUserId));
          bodyFormData.append('keywordsOn', JSON.stringify(keywordActiveTemp));
          bodyFormData.append('friendsOn', JSON.stringify(friendsActiveTemp));
          bodyFormData.append('followsOn', JSON.stringify(followsActiveTemp));
          bodyFormData.append('keywords', JSON.stringify(newsFeedFilter_keywords));
          bodyFormData.append('friends', JSON.stringify(newsFeedFilter_friends));
          bodyFormData.append('friendsInterests', JSON.stringify(newsFeedFilter_friendsInterests));
          bodyFormData.append('follows', JSON.stringify(newsFeedFilter_follows));

          return axios(
          {
               url: 'https://api.penewo.com/api/return-news-feed-items',
               method: 'post',
               data: bodyFormData,
               headers: {'Content-Type': 'multipart/form-data' }
          }).then(response => {
               console.log(response);

               if(response.data.errorCode === 1)
               {
                    console.log(response.data.uploads);

                    // let newsFeedData = newsFeedItems;

                    setNewsFeedItems(response.data.uploads);
               }
               else
               {
                    setNewsFeedItems([]);
               }
          });
     }

     function setFilterKeywordActiveState(isSet: any)
     {
          setNewsFeedFilter_keywordsOn(isSet);

          keywordActiveTemp = isSet;
          friendsActiveTemp = newsFeedFilter_friendsOn;
          followsActiveTemp = newsFeedFilter_followsOn;

          updateNewsFeedFilters();
     }

     function setFilterFriendActiveState(isSet: any)
     {
          setNewsFeedFilter_friendsOn(isSet);

          keywordActiveTemp = newsFeedFilter_keywordsOn;
          friendsActiveTemp = isSet;
          followsActiveTemp = newsFeedFilter_followsOn;

          updateNewsFeedFilters();
     }

     function setFilterFollowsActiveState(isSet: any)
     {
          setNewsFeedFilter_followsOn(isSet);

          keywordActiveTemp = newsFeedFilter_keywordsOn;
          friendsActiveTemp = newsFeedFilter_friendsOn;
          followsActiveTemp = isSet;

          updateNewsFeedFilters();
     }

     function download(filePath:string)
     {
          var element = document.createElement("a");
          var file = new Blob(
               [
                    filePath
               ],
               { type: "image/*" }
          );

          element.href = URL.createObjectURL(file);
          element.download = "image.jpg";
          element.click();
     }

     return (
          <IonPage style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
               <IonContent>
                    <div style={{marginTop:60}}>
                         <div>
                              <IonGrid>
                                   <IonRow style={{background: '#FFF', position: 'relative', zIndex: 100}}>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => setShowModalKeywords(true)} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 4, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Your <br />Interests</div>
                                                  <div className={(newsFeedFilter_keywordsOn === "1") ? "activeSwitch" : "inactiveSwitch"}></div>
                                             </div>
                                        </IonCol>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => setShowModalFriends(true)} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 10, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Friends</div>
                                                  <div className={(newsFeedFilter_friendsOn === "1") ? "activeSwitch" : "inactiveSwitch"}></div>
                                             </div>
                                        </IonCol>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => setShowModalFollows(true)} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 4, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Your <br />Follows</div>
                                                  <div className={(newsFeedFilter_followsOn === "1") ? "activeSwitch" : "inactiveSwitch"}></div>
                                             </div>
                                        </IonCol>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => setShowModalKeywordSuggestions(true)} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 4, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Interests <br />Suggestions</div>
                                             </div>
                                        </IonCol>
                                   </IonRow>
                              </IonGrid>

                         </div>
                         <div>
                              {
                                   newsFeedItems.map(newsFeedItem => {
                                        return (
                                             <div style={{marginBottom: 30, paddingLeft: 15, paddingRight: 15, position: 'relative'}}>

                                                  <div style={{background: '#FAFAFA', height: 45, paddingTop: 5, borderTop: '1px solid #F3F3F3', position: 'relative'}}>
                                                       <div style={{float: 'left', marginRight: 10, height: 40, zIndex: 5, position: 'relative'}}>
                                                            <a href={"profile/"+newsFeedItem["profiles_id"]}><img src={newsFeedItem["profile_picture"]} style={{width: 65, height:65, borderRadius: 10, marginLeft: 5, background: '#FFF', border: '1px solid #FFF'}} /></a>
                                                       </div>
                                                       <div>
                                                            <a href={"profile/"+newsFeedItem["profiles_id"]} style={{textDecoration: 'none'}}><div style={{fontSize: 16, paddingTop: 8, color: '#AAA'}}>{newsFeedItem["name"]}</div></a>
                                                       </div>

                                                  </div>

                                                  <img src={newsFeedItem["path"]} style={{marginBottom: -4}} />

                                                  <IonGrid style={{padding: 0}}>
                                                       <IonRow style={{padding: 0}}>
                                                            <IonCol><div style={{background: '#000', opacity: 0.5, fontSize: 13, paddingTop: 5, paddingBottom: 5, textAlign: 'center', color: '#FFF', borderRadius: 10}}>Like</div></IonCol>
                                                            <IonCol><div style={{background: '#000', opacity: 0.5, fontSize: 13, paddingTop: 5, paddingBottom: 5, textAlign: 'center', color: '#FFF', borderRadius: 10}}>Share</div></IonCol>
                                                            <IonCol><div style={{background: '#000', opacity: 0.5, fontSize: 13, paddingTop: 5, paddingBottom: 5, textAlign: 'center', color: '#FFF', borderRadius: 10}}>Comment</div></IonCol>
                                                            <IonCol><div onClick={() => download(newsFeedItem["path"])} style={{background: '#000', opacity: 0.5, fontSize: 13, paddingTop: 5, paddingBottom: 5, textAlign: 'center', color: '#FFF', borderRadius: 10}}>Download</div></IonCol>
                                                       </IonRow>
                                                  </IonGrid>

                                                  <div style={{marginBottom: 10, backgroundColor: '#eeeeee', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, textAlign: 'left', fontSize: 14}}>{newsFeedItem["title"]}</div>
                                                  <div style={{marginBottom: 10, paddingLeft: 15, textAlign: 'left', fontSize: 12}}>{newsFeedItem["upload_description"]}</div>
                                             </div>
                                        )
                                   })
                              }
                         </div>
                    </div>

                    <IonModal isOpen={showModalKeywordSuggestions} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Interests Suggestions</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalKeywordSuggestions(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>

                         </IonContent>
                    </IonModal>

                    <IonModal isOpen={showModalKeywords} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Interests Settings</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => updateKeywords()}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <IonList style={{marginLeft: -20, marginBottom: 10}}>
                                   <IonItem>
                                        <IonLabel style={{paddingLeft: 20}}>Set On/Off</IonLabel>
                                        <IonToggle color="success" checked={(newsFeedFilter_keywordsOn === "1") ? true : false} onIonChange={e => (newsFeedFilter_keywordsOn === "1") ? setFilterKeywordActiveState("0") : setFilterKeywordActiveState("1")} />
                                   </IonItem>
                              </IonList>
                              <IonList>
                                   {
                                        userKeywords.map(keywordData => {
                                             return (
                                                  <IonItem key={keywordData["tid"]}>
                                                       <IonLabel>{keywordData["name"]}</IonLabel>
                                                       <IonCheckbox onIonChange={(e) => setKeywordActiveState(keywordData["tid"], e.detail.checked)} checked={checkKeywordActive(keywordData["tid"])} slot="start" />
                                                  </IonItem>
                                             )
                                        })
                                   }
                              </IonList>
                              <IonButton color="primary" expand="full" size="small" style={{width: '100%', height: 35, position: 'fixed', bottom: 0}}>Add Area of Interest</IonButton>
                         </IonContent>
                    </IonModal>

                    <IonModal isOpen={showModalFriends} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Friends Settings</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalFriends(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <IonList style={{marginLeft: -20, marginBottom: 10}}>
                                   <IonItem>
                                        <IonLabel style={{paddingLeft: 20}}>Set On/Off</IonLabel>
                                        <IonToggle color="success" checked={(newsFeedFilter_friendsOn === "1") ? true : false} onIonChange={e => (newsFeedFilter_friendsOn === "1") ? setFilterFriendActiveState("0") : setFilterFriendActiveState("1")} />
                                   </IonItem>
                              </IonList>
                              <IonList>
                                   {
                                        userFriends.map(userData => {
                                             return (
                                                  <IonItem key={userData["id"]}>
                                                       <IonAvatar>
                                                            <img src={userData["profile_picture"]} />
                                                       </IonAvatar>
                                                       <IonLabel style={{paddingLeft: 10}}>{userData["name"]}</IonLabel>
                                                       <IonCheckbox  checked={checkFriendActive(userData["id"])} slot="start" />
                                                  </IonItem>
                                             )
                                        })
                                   }
                              </IonList>
                         </IonContent>
                    </IonModal>

                    <IonModal isOpen={showModalFollows} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Follows Settings</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalFollows(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <IonList style={{marginLeft: -20, marginBottom: 10}}>
                                   <IonItem>
                                        <IonLabel style={{paddingLeft: 20}}>Set On/Off</IonLabel>
                                        <IonToggle color="success" checked={(newsFeedFilter_followsOn === "1") ? true : false} onIonChange={e => (newsFeedFilter_followsOn === "1") ? setFilterFollowsActiveState("0") : setFilterFollowsActiveState("1")} />
                                   </IonItem>
                              </IonList>
                              <IonList>
                                   {
                                        userFollows.map(userData => {
                                             return (
                                                  <IonItem key={userData["id"]}>
                                                       <IonAvatar>
                                                            <img src={userData["profile_picture"]} />
                                                       </IonAvatar>
                                                       <IonLabel style={{paddingLeft: 10}}>{userData["name"]}</IonLabel>
                                                       <IonCheckbox  checked={checkFollowActive(userData["id"])} slot="start" />
                                                  </IonItem>
                                             )
                                        })
                                   }
                              </IonList>
                         </IonContent>
                    </IonModal>

                    <IonActionSheet
                         isOpen={showActionSheet}
                         onDidDismiss={() => setShowActionSheet(false)}
                         buttons={[{
                              text: 'Like',
                              handler: () => {
                                   console.log('Delete clicked');
                              }
                         }, {
                              text: 'Share',
                              handler: () => {
                                   console.log('Share clicked');
                              }
                         }, {
                              text: 'Comments',
                              handler: () => {
                                   console.log('Play clicked');
                              }
                         }, {
                              text: 'Download',
                              handler: () => {
                                   console.log('Favorite clicked');
                              }
                         }, {
                              text: 'Cancel',
                              role: 'cancel',
                              handler: () => {
                                   console.log('Cancel clicked');
                              }
                         }]}
                    ></IonActionSheet>

               </IonContent>
          </IonPage>
  );
};

export default NewsFeed;
