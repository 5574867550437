import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditFeaturedUploads.css';

const EditFeaturedUploads: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [featuredUploads, setFeaturedUploads] = React.useState([]);
     const [uploadId, setUploadId] = React.useState('');

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     const fetchFeaturedUploads= () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-featured-uploads/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchFeaturedUploads().then(data => {
               setFeaturedUploads(data);
          });
     }, []);

     function uploadFile(selectorFiles: FileList)
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               console.log(selectorFiles);
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append("file", selectorFiles[0]);

               return axios(
               {
                    url: 'https://api.penewo.com/api/add-upload/featured',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);

                    setSubmitForm(false);

                    fetchFeaturedUploads().then(data => {
                         setFeaturedUploads(data);
                    });

               });
          }
     }

     function deleteUpload(uploadIdValue: string)
     {
          setUploadId(uploadIdValue);

          if(privateUserId !== null)
          {
               let deleteFormData = new FormData();
               deleteFormData.append('privateUserId', privateUserId);
               deleteFormData.append('uploadId', uploadIdValue);

               console.log(deleteFormData);

               return axios(
               {
                    url: 'https://api.penewo.com/api/delete-upload',
                    method: 'post',
                    data: deleteFormData
               }).then(response => {
                    console.log(response);

                    fetchFeaturedUploads().then(data => {
                         setFeaturedUploads(data);
                         setUploadId('');
                    });

               });
          }
     }


     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Featured Images</h2>
                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20}}>

                              <div style={{textAlign: 'center', marginBottom: 25}}>
                                   <div style={{marginBottom: 5, fontSize: 14, fontWeight: 'bold'}}>Upload New Featured Image</div>
                                   <input type="file" onChange={ (e) => uploadFile(e.target.files as FileList) } style={{border: '1px solid #AAA', borderRadius: 5}} />
                                   <br /><br />
                                   {(!submitForm) ? "" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: 30, background: '#AAA', borderRadius: 50}} />}
                              </div>

                              {
                                   featuredUploads.map(featuredUpload => {
                                        return (
                                             <div key={featuredUpload["id"]} style={{padding: 6, borderBottom: '1px solid #DDD'}}>
                                                  <div style={{ backgroundImage: "url('"+featuredUpload["path"]+"')", backgroundPosition: 'center center', backgroundSize: 'cover', display: 'inline-block', verticalAlign: 'middle', marginRight: 30, border: '1px solid #DDD', width: 100, height: 100}}></div>
                                                  <div onClick={() => {deleteUpload(featuredUpload["id"] as string);}} style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'inline-block', color: '#FFF', height: 34, padding: '9px 15px', borderRadius: 4, textAlign: 'center', textDecoration: 'none', fontSize: 14, verticalAlign: 'middle'}}>{(uploadId === featuredUpload["id"]) ? <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} /> : "Delete"}</div>
                                             </div>
                                        )
                                   })
                              }

                         </div>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditFeaturedUploads;
