import React from 'react';
import axios from 'axios';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import { IonAlert, IonContent, IonInput, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import './Share.css';

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
}> {}

const Share: React.FC<UserDetailPageProps> = ({match}) => {
     const userId = match.params.id;

     const [userData, setUserData] = React.useState([]);

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => setUserData(data));
     }, []);

     return (
          <IonPage>
               <IonContent>

               <div style={{position: 'fixed', top: 60, left: -10, right: -10, paddingLeft: 10, paddingRight: 10, background: '#FFF', zIndex: 10}}>
                    <IonGrid style={{paddingBottom: 0}}>
                         <IonRow style={{background: '#FFF', paddingTop: 5, paddingBottom: 0, position: 'relative', zIndex: 100}}>
                              <IonCol style={{paddingLeft: 8, paddingRight: 8}}>
                                   <div style={{textAlign: 'center', height: 30, width: '100%', textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', paddingTop: 9, paddingBottom: 3, background: '#7386ae', border: 0, color: '#FFF'}}>
                                        Save Namecard
                                   </div>
                              </IonCol>
                         </IonRow>
                    </IonGrid>
               </div>

               <h2 style={{marginTop: 135, marginBottom: 20, textAlign: 'center', fontSize: 16}}>Want to share Name Card with Social Media?</h2>

               {
                    userData.map(userInfo => {

                         if(userInfo['is_profile'] !== 0)
                         {
                              return (
                                   <div key={userInfo["id"]}>
                                        <div style={{marginBottom: 4}}>
                                             <div style={{position: 'relative', width: '100%'}}>
                                                  <div style={{overflow: 'visible', borderBottom: '1px solid #FFF', height: 140}}>
                                                       <div style={{display: 'inline-block', verticalAlign: 'top', float: 'left'}}>
                                                            <img src={userInfo["profile_picture"]} style={{height: 140}} />
                                                       </div>
                                                       <div style={{display: 'inline-block', verticalAlign: 'top', marginLeft: -5, background: 'linear-gradient(to right, '+userInfo["micro_color"]+', '+userInfo["micro_color_end"]+')', height: 140, position: 'absolute', left: 140, right: 0}}>
                                                            <div style={{paddingLeft: 12, paddingTop: 5, paddingBottom: 5}}>
                                                            <h2 style={{color: '#FFF', fontSize: 16, marginTop: 0, marginBottom: 0}}>{userInfo["micro_name"] !== "" ? userInfo["micro_name"] : userInfo["name"]}</h2>
                                                            <div style={{color: '#FFF', fontSize: 13, whiteSpace: 'pre-line', maxHeight: 30, overflow: 'hidden'}}>
                                                                 <div>{userInfo["micro_position"]}</div>
                                                                 </div>
                                                            </div>
                                                            <div style={{position:'absolute', bottom: 0, paddingLeft: 12, color: '#FFF', fontSize: 16, paddingBottom: 5}}>
                                                                 <span>{userInfo["micro_city"] !== "" ? userInfo["micro_city"] : userInfo["city_name"]}</span><br /><span>{userInfo["micro_country"] !== "" ? userInfo["micro_country"] : userInfo["country_name"]}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div style={{borderTop: '2px solid #FFF', height: 50, color: '#FFF', paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8, background: '#8c8d8f'}}>
                                                  <div style={{height: 35, fontSize: 14, whiteSpace: 'pre-line', overflow: 'hidden'}}>{userInfo["micro_content"]}</div>
                                             </div>
                                        </div>


                                        <div style={{paddingLeft: 15, paddingRight: 15, borderBottom: '1px solid #AAA', height: 46, marginBottom: 10}}>
                                             <div style={{float: 'right'}}>
                                                  <div id="qrCodeContainer" data-url="https://nc.penewo.com/5DC29r"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAEbUlEQVRYR72ZjU3kMBCFnQ7oAKgAqACoBOgAKlioADoAKtmlAqACoAOoYNFn3YtezNhO0N1ZWu0Sx57n+XkzY4aU0jbNHOv1Or95enoarmD+5OQkz11fX6ebm5u03W7H37310aYDAI+Pj8eNo5c2m016enpKCNjb20sPDw+T1zR/dnaW9zk/P0884wNQgXWAc2VmgKvVKm9SGy5AGvJ3Nc8zBAMsmneAc2VOAF5eXqaXl5c0DEM2Dd9sGgFEi4+Pj5P529vbtLOzk5+jTTTJeH9/z5/Dw8O8Py4igPyWLL555+7ubpQ5AYh2MKWP0oekQQDywc/u7+8zKAAyrq6uRgC+38HBwQ+AKMGHLCCl/BqgNi0F6Lk05PORif87wN3d3dG0chUPMrFAdAD34X+mwdJENZr5FcAlURzxnK+vRSna9CDpyRx5sCRfRRYb4DvOgwQE8wyZkd/iR0UvcwB1niSakYWmI5naUzIzwCoBFhM1J5e2FOEi6L+SSdbr9WyAzmMEw/7+/kRDz8/PWaO8p0wByLe3t/Tx8ZEtoT3mKmXYbDZbmROeYry+vo7kiSDMggDm+Q2h6xlrxYkuFG1iQr45DCkSAuab/fWsBlT7T0wcJfMaUXuQREI8/XnALA6S1Wq1dSdGGEGgAUAlfifnMkgwLelN632NA0QzCiaRtKe6MvAG7OunLbUhIDz3VNjLFD3Kaclk7bh/Wc2UJqilKtdwVE7ViLhMhfwdFSNhJkH1AMTELqBVu3m9h4kJgsgVIj/V4cV5Wq8qCu6tFgsOMOI5FxjVg0uCqEU5AzzIqflQq31+fub3FTh8i/PgOcbR0dFIQ/ztGqYehOtUA8KD7g4KAufGCKDqzQkPEhASIG5DzRFniafYHNNcXFxk0A4Q7vQKHB5l3gcH8P1REDyJ2VHOj1RXluzKp2VLUIvCsppxMN5UuZ+2ePIHQExNsm9y059WAO2yuYb4DW3K8R2gN1UOUIUDMrEMQapn3WLBM0nkKzWerDl+ZKFmkNS6uihV9YhaAeNdXc9FSgU0exI/iVIVThyZQH7DQTxV4iLeFUZ1o/Mc6/WOu8i4rtcX95qeMhPwtxeiPRfhfedZdYEjDzsPRr7g5qr1teJBNACXQhPqi3ELuQttKXv48PI/dCGvB1no0VtWG14Pqt7jpPpNf0wUMmAClf6eawHo9aD7qC4OWK89u1Fc47Ha5VDraqTXMrisSbEw9yKnd3vlyd5LfgWcXz5xED69KP/15dHctrMMslLDiwDOuZspo7SMQv4uebBFxL5eaz1wFt/NCKCbUJRQ9sW8W7u7cbeSuR3gJNVFPKRTz7m6aN319S6XSu2qoh9ztRP1HBOLC1VDKgAgWL/fk4DyMlPPtb68H1S5FQJ0HnIBLR7zTAJPckj6X9WLmEpDBbGnQu+bmf/6+poodXEUywfdrK2mZ06103KxyeVR1OsCyC+PBNAdO2p6tJcXE2QaqmxGyb1+CaVgGZumHg1ofsm/ESINu5y5FvgG2PaxsUENE/oAAAAASUVORK5CYII=" /></div>
                                             </div>
                                             <div style={{fontSize:14, paddingTop:4}}><span style={{fontSize: 12, fontWeight: 'bold'}}>send to friends</span><br />https://nc.penewo.com/{userInfo["card_url"]}</div>
                                        </div>
                                   </div>
                              )
                         }
                    })
               }

               <div style={{paddingLeft: 15, paddingRight: 15}}>
                    <div style={{fontSize:16, fontStyle:'italic', paddingLeft:10}}>send to:</div>

                    <IonGrid>
                         <IonRow>
                              <IonCol style={{textAlign: 'center'}}>
                                   <div style={{display: 'inline-block', width: 50, height: 50, padding: 8, background: '#3B5999'}}>
                                        <a target="_blank" href={"https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdev.penewo.com%2Fprofile%2F"+userId}>
                                             <img src="./assets/icon/social-share/facebook.png" />
                                        </a>
                                   </div>
                              </IonCol>
                              <IonCol style={{textAlign: 'center'}}>
                                   <div style={{display: 'inline-block', width: 50, height: 50, padding: 8, background: '#00ACEE'}}>
                                        <a target="_blank" href={"https://twitter.com/share?url=https%3A%2F%2Fdev.penewo.com%2Fprofile%2F"+userId+"&amp;text=Penewo%20-%20The%20People%20Connector"}>
                                             <img src="./assets/icon/social-share/twitter.png" />
                                        </a>
                                   </div>
                              </IonCol>
                              <IonCol style={{textAlign: 'center'}}>
                                   <div style={{display: 'inline-block', width: 50, height: 50, padding: 8, background: '#007BB6'}}>
                                        <a target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fdev.penewo.com%2Fprofile%2F"+userId}>
                                             <img src="./assets/icon/social-share/linkedin.png" />
                                        </a>
                                   </div>
                              </IonCol>
                              <IonCol style={{textAlign: 'center'}}>
                                   <div style={{display: 'inline-block', width: 50, height: 50, padding: 8, background: '#CB2029'}}>
                                        <a target="_blank" href={"https://pinterest.com/pin/create/bookmarklet/?&amp;url=https%3A%2F%2Fdev.penewo.com%2Fprofile%2F"+userId+"&amp;description=Penewo%20-%20The%20People%20Connector"}>
                                             <img src="./assets/icon/social-share/pinterest.png" />
                                        </a>
                                   </div>
                              </IonCol>
                         </IonRow>
                    </IonGrid>
               </div>

               </IonContent>
          </IonPage>
  );
};

export default Share;
