import React from 'react';
import axios from 'axios';
import Cropper from 'react-easy-crop'
import { IonContent, IonInput, IonLabel, IonRange, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import { RangeValue } from '@ionic/core';
import './EditProfilePicture.css';

const EditProfilePicture: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [ submitFormCrop, setSubmitFormCrop ] = React.useState(false);
     const [isTemp, setIsTemp] = React.useState('0');
     const [profilePicture, setProfilePicture] = React.useState('');
     const [crop, setCrop] = React.useState({ x: 0, y: 0 });
     const [exportCrop, setExportCrop] = React.useState({ x: 0, y: 0 , width: 0, height: 0});
     const [zoom, setZoom] = React.useState(1);
     const [aspect, setAspect] = React.useState(1);
     const [rotation, setRotation] = React.useState(0);

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setProfilePicture(data[0]["profile_picture"]);
          });
     }, []);

     const onCropChange = (crop:any) => {
          setCrop(crop)
     }

     const onCropComplete = (croppedArea:any, croppedAreaPixels:any) => {
          console.log(croppedArea, croppedAreaPixels);
          setExportCrop(croppedAreaPixels);
     }

     const onZoomChange = (zoom:any) => {
          setZoom(zoom);
     }

     function uploadFile(selectorFiles: FileList)
     {
          setSubmitForm(true);
          setIsTemp('1');

          if(privateUserId !== null)
          {
               console.log(selectorFiles);
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append("file", selectorFiles[0]);

               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/profile-picture',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);

                    setSubmitForm(false);

                    setProfilePicture(response.data);

               });
          }
     }

     function cropProfilePicture()
     {
          setSubmitFormCrop(true);

          if(privateUserId !== null)
          {

               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('is_temp', isTemp);
               bodyFormData.append('x', JSON.stringify(exportCrop.x));
               bodyFormData.append('y', JSON.stringify(exportCrop.y));
               bodyFormData.append('width', JSON.stringify(exportCrop.width));
               bodyFormData.append('height', JSON.stringify(exportCrop.height));

               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/profile-picture-crop',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }


     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, paddingTop: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Profile Picture</h2>
                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 15, paddingBottom: 10}}>

                              <div style={{textAlign: 'center', marginBottom: 20}}>
                                   <div style={{marginBottom: 5, fontSize: 14, fontWeight: 'bold'}}>Upload Your Profile Picture</div>
                                   <input type="file" onChange={ (e) => uploadFile(e.target.files as FileList) } style={{border: '1px solid #AAA', borderRadius: 5}} />
                                   <br /><br />
                                   {(!submitForm) ? "" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: 30, background: '#AAA', borderRadius: 50}} />}
                              </div>

                              <div style={{position: 'relative', overflow: 'hidden', width: '100%', height: 300}}>
                                   <Cropper
                                        image={profilePicture}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={aspect}
                                        rotation={rotation}
                                        restrictPosition={true}
                                        cropSize={{width: 250, height: 250}}
                                        onCropChange={onCropChange}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={onZoomChange}
                                   />
                              </div>

                              <div style={{marginLeft: 15, marginRight: 15}}>
                                   <IonRange min={1} max={3} step={0.1} onIonChange={e => setZoom(e.detail.value as number)}></IonRange>
                              </div>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => cropProfilePicture()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitFormCrop) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditProfilePicture;
