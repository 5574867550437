import React from 'react';
import axios from 'axios';
import Cropper from 'react-easy-crop'
import { IonAlert, IonContent, IonInput, IonTextarea, IonLabel, IonRange, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol, IonSearchbar, IonModal, IonButtons, IonButton, IonHeader, IonToolbar } from '@ionic/react';
import { RangeValue } from '@ionic/core';
import './Register.css';

const Register: React.FC = () => {

     const [containerProfilePictureClass, setContainerProfilePictureClass] = React.useState("container-shown");
     const [containerNameClass, setContainerNameClass] = React.useState("container-hidden");
     const [containerAddressClass, setContainerAddressClass] = React.useState("container-hidden");
     const [containerNamecardClass, setContainerNamecardClass] = React.useState("container-hidden");

     const [showAlert, setShowAlert] = React.useState(false);
     const [alertMessage, setAlertMessage] = React.useState('');

     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [ submitFormCrop, setSubmitFormCrop ] = React.useState(false);
     const [isTemp, setIsTemp] = React.useState('0');
     const [profilePicture, setProfilePicture] = React.useState('https://dev.penewo.com/users/temp/avatar-placeholder.jpg');
     const [profilePictureName, setProfilePictureName] = React.useState('avatar-placeholder.jpg');
     const [croppedProfilePicture, setCroppedProfilePicture] = React.useState('https://dev.penewo.com/users/temp/avatar-placeholder.jpg');
     const [crop, setCrop] = React.useState({ x: 0, y: 0 });
     const [exportCrop, setExportCrop] = React.useState({ x: 0, y: 0 , width: 250, height: 250});
     const [zoom, setZoom] = React.useState(1);
     const [aspect, setAspect] = React.useState(1);
     const [rotation, setRotation] = React.useState(0);

     const [name, setName] = React.useState('');
     const [username, setUsername] = React.useState('');
     const [email, setEmail] = React.useState('');
     const [password, setPassword] = React.useState('');
     const [quote, setQuote] = React.useState('');
     const [intro, setIntro] = React.useState('');

     const [microColor, setMicroColor] = React.useState('#b6656e');
     const [microEndColor, setMicroEndColor] = React.useState('#722029');
     const [city, setCity] = React.useState('');
     const [country, setCountry] = React.useState('');
     const [position, setPosition] = React.useState('');
     const [microIntro, setMicroIntro] = React.useState('');

     const colorArray = new Array();
     colorArray.push({"start": "#b6656e", "color": "#722029"});
     colorArray.push({"start": "#bc7eac", "color": "#6c305d"});
     colorArray.push({"start": "#ca699d", "color": "#89265a"});
     colorArray.push({"start": "#d5bc97", "color": "#917651"});
     colorArray.push({"start": "#9f896a", "color": "#5b4425"});
     colorArray.push({"start": "#57866e", "color": "#16432b"});
     colorArray.push({"start": "#8eaac3", "color": "#4b657e"});
     colorArray.push({"start": "#7f95bb", "color": "#33486c"});
     colorArray.push({"start": "#56a5c0", "color": "#125f7b"});
     colorArray.push({"start": "#868787", "color": "#424142"});

     const [ showModal, setShowModal ] = React.useState(false);

     const [building, setBuilding] = React.useState('');
     const [address, setAddress] = React.useState('');
     const [zipcode, setZipcode] = React.useState('');

     const [locationLabel, setLocationLabel] = React.useState('');
     const [locationSubLabel, setLocationSubLabel] = React.useState('');
     const [locationType, setLocationType] = React.useState('');
     const [locationId, setLocationId] = React.useState(0);

     const [searchTerm, setSearchTerm] = React.useState('');
     const [locations, setLocations] = React.useState([]);
     const [timerIsSet, setTimerIsSet] = React.useState(false);

     let timer = setTimeout(function(){
          console.log('timer is running');
     }, 1000*60*10);

     function searchLocation(searchValue: string)
     {
          setSearchTerm(searchValue);

          clearTimeout(timer);
          console.log('cleared - '+searchValue);

          timer = setTimeout(function()
          {
               setTimerIsSet(true);
               return axios(
               {
                    url: 'https://api.penewo.com/api/search-location?term='+searchValue,
                    method: 'get'
               }).then(response => {
                    console.log(response);

                    setLocations(response.data);

               });
          }, 2500);
     }

     React.useEffect(() => {

     }, []);

     const onCropChange = (crop:any) => {
          setCrop(crop)
     }

     const onCropComplete = (croppedArea:any, croppedAreaPixels:any) => {
          console.log(croppedArea, croppedAreaPixels);
          setExportCrop(croppedAreaPixels);
     }

     const onZoomChange = (zoom:any) => {
          setZoom(zoom);
     }

     function uploadFile(selectorFiles: FileList)
     {
          setSubmitForm(true);

               console.log(selectorFiles);
               let bodyFormData = new FormData();
               bodyFormData.append("file", selectorFiles[0]);

               return axios(
               {
                    url: 'https://api.penewo.com/api/temp-profile-picture-upload',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);

                    setSubmitForm(false);

                    setProfilePicture(response.data.filepath);
                    setProfilePictureName(response.data.filename);
               });
     }

     function cropProfilePicture()
     {
          setSubmitFormCrop(true);

               let bodyFormData = new FormData();
               bodyFormData.append('filename', profilePictureName);
               bodyFormData.append('profilePicture', profilePicture);
               bodyFormData.append('x', JSON.stringify(exportCrop.x));
               bodyFormData.append('y', JSON.stringify(exportCrop.y));
               bodyFormData.append('width', JSON.stringify(exportCrop.width));
               bodyFormData.append('height', JSON.stringify(exportCrop.height));

               return axios(
               {
                    url: 'https://api.penewo.com/api/temp-profile-picture-crop',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);


                    setCroppedProfilePicture(response.data);

                    setContainerProfilePictureClass('container-hidden');
                    setContainerNameClass('container-shown');
               });
     }

     function updateLocation(locationData:any)
     {
          console.log(locationData);

          setLocationLabel(locationData["value"]);
          setLocationSubLabel(locationData["value_sub"]);
          setLocationType(locationData["type"]);
          setLocationId(locationData["data"]);

          setShowModal(false);
     }

     function nextSection(sectionName:string)
     {
          if(sectionName === "address")
          {
               if(name == "" || username == "" || email == "" || password == "")
               {
                    var errorMessage = "";
                    if(name == "")
                    {
                         errorMessage += "Enter a Name<br /><br />";
                    }

                    if(username == "")
                    {
                         errorMessage += "Enter a Username<br /><br />";
                    }

                    if(email == "")
                    {
                         errorMessage += "Enter a valid Email Address<br /><br />";
                    }

                    if(password == "")
                    {
                         errorMessage += "Enter a Password<br /><br />";
                    }

                    setAlertMessage(errorMessage);
                    setShowAlert(true);
               }
               else
               {
                    setContainerNameClass('container-hidden');
                    setContainerAddressClass('container-shown');
               }
          }
          else if(sectionName === "namecard")
          {
               setContainerAddressClass('container-hidden');
               setContainerNamecardClass('container-shown');
          }
     }

     function prevSection(sectionName:string)
     {
          if(sectionName === "profilePicture")
          {
               setContainerProfilePictureClass('container-shown');
               setContainerNameClass('container-hidden');
          }
          else if(sectionName === "name")
          {
               setContainerNameClass('container-shown');
               setContainerAddressClass('container-hidden');
          }
          else if(sectionName === "address")
          {
               setContainerAddressClass('container-shown');
               setContainerNamecardClass('container-hidden');
          }
     }


     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, paddingTop: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20, fontSize: 16, display:'none'}}>Please set your Profile</h2>
                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 15, paddingBottom: 10}}>

                              <div className={containerProfilePictureClass}>
                                   <div style={{textAlign: 'center', marginBottom: 20}}>
                                        <div style={{marginBottom: 5, fontSize: 14, fontWeight: 'bold'}}>Upload Your Profile Picture</div>
                                        <input type="file" onChange={ (e) => uploadFile(e.target.files as FileList) } style={{border: '1px solid #AAA', borderRadius: 5}} />
                                        <br /><br />
                                        {(!submitForm) ? "" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: 30, background: '#AAA', borderRadius: 50}} />}
                                   </div>

                                   <div style={{position: 'relative', overflow: 'hidden', width: '100%', height: 300}}>
                                        <Cropper
                                             image={profilePicture}
                                             crop={crop}
                                             zoom={zoom}
                                             aspect={aspect}
                                             rotation={rotation}
                                             restrictPosition={true}
                                             cropSize={{width: 250, height: 250}}
                                             onCropChange={onCropChange}
                                             onCropComplete={onCropComplete}
                                             onZoomChange={onZoomChange}
                                        />
                                   </div>

                                   <div style={{marginLeft: 15, marginRight: 15}}>
                                        <IonRange min={1} max={3} step={0.1} onIonChange={e => setZoom(e.detail.value as number)}></IonRange>
                                   </div>

                                   <IonGrid>
                                        <IonRow style={{marginTop: 20}}>
                                             <IonCol style={{padding: 0}}>
                                                  <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="register-notice">Cancel</a>
                                             </IonCol>
                                             <IonCol style={{padding: 0}}>
                                                  <button onClick={() => cropProfilePicture()} style={{background: 'linear-gradient(to bottom, #337ab7 0%, #265a88 100%)', borderColor: '#245580', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitFormCrop) ? "Next" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                             </IonCol>
                                        </IonRow>
                                   </IonGrid>

                              </div>
                              <div className={containerNameClass}>
                                   <div style={{marginTop: -16, background: '#FFF', paddingTop: 20, paddingBottom: 20, paddingRight: 15}}>

                                        <IonList>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Name</IonLabel>
                                                  <IonInput value={name} placeholder="Enter your Name" onIonChange={(e) => setName((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Username</IonLabel>
                                                  <IonInput value={username} placeholder="Enter your Username" onIonChange={(e) => setUsername((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Email</IonLabel>
                                                  <IonInput value={email} placeholder="Enter your Email Address" onIonChange={(e) => setEmail((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Password</IonLabel>
                                                  <IonInput type="password" placeholder="Enter your Password" value={password} onIonChange={(e) => setPassword((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Favourite Quote</IonLabel>
                                                  <IonInput value={quote} placeholder="Enter your Favourite Quote" onIonChange={(e) => setQuote((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 5}}>
                                                  <IonLabel position="stacked">Intro</IonLabel>
                                                       <IonTextarea rows={4} placeholder="Enter your Introduction" value={intro} onIonChange={(e) => setIntro((e.target as HTMLInputElement).value)}></IonTextarea>
                                             </IonItem>
                                        </IonList>

                                        <IonGrid>
                                             <IonRow style={{marginTop: 20}}>
                                                  <IonCol style={{padding: 0}}>
                                                       <button onClick={() => prevSection('profilePicture')} style={{background: 'linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%)', borderColor: '#28a4c9', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}}>Prev</button>
                                                  </IonCol>
                                                  <IonCol style={{padding: 0}}>
                                                       <button onClick={() => nextSection('address')} style={{background: 'linear-gradient(to bottom, #337ab7 0%, #265a88 100%)', borderColor: '#245580', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Next" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>

                                   </div>
                              </div>

                              <div className={containerAddressClass}>
                                   <div onClick={() => setShowModal(true)} style={{marginTop: 10, marginLeft: 15, marginRight: 15, paddingBottom: 30, marginBottom: 30, borderBottom: '1px solid #DDD'}}>
                                        <div style={{background: '#EEE', padding: 5, fontSize: 14}}>
                                             <div style={{display: 'inline-block', verticalAlign: 'top', width: '25%', fontStyle: 'italic'}}>Location: </div>
                                             <div style={{display: 'inline-block', width: '75%', borderLeft: '1px solid #AAA', paddingLeft: 6, minHeight: 35}}>
                                                  <div style={{minHeight: 35}}>
                                                       <span>{locationLabel}</span>
                                                       <div style={{float: 'right', fontSize: 11, color: '#AAA', marginRight: 4}}>{locationType}</div>
                                                       <div style={{fontSize: 11, color: '#AAA'}}>{locationSubLabel}</div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div style={{marginTop: 6, background: '#FFF', paddingBottom: 20, paddingLeft: 15, paddingRight: 15}}>



                                        <IonList>

                                             <IonItem style={{marginLeft: -20, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Building</IonLabel>
                                                  <IonInput value={building} placeholder="Enter Building Information..." onIonChange={(e) => setBuilding((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -20, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Address</IonLabel>
                                                       <IonInput value={address} placeholder="Enter your Address..." onIonChange={(e) => setAddress((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -20, marginBottom: 5}}>
                                                  <IonLabel position="stacked">Zip Code</IonLabel>
                                                       <IonInput value={zipcode} placeholder="Enter your Zip Code..." onIonChange={(e) => setZipcode((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>
                                        </IonList>

                                        <IonGrid>
                                             <IonRow style={{marginTop: 20}}>
                                                  <IonCol style={{padding: 0}}>
                                                       <button onClick={() => prevSection('name')} style={{background: 'linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%)', borderColor: '#28a4c9', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}}>Prev</button>
                                                  </IonCol>
                                                  <IonCol style={{padding: 0}}>
                                                       <button  onClick={() => nextSection('namecard')} style={{background: 'linear-gradient(to bottom, #337ab7 0%, #265a88 100%)', borderColor: '#245580', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Next" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>

                                   </div>
                              </div>

                              <div className={containerNamecardClass}>
                                   <div style={{marginBottom: 15}}>
                                        <div style={{position: 'relative', width: '100%'}}>
                                             <div style={{overflow: 'visible', borderBottom: '1px solid #FFF', height: 140}}>
                                                  <div style={{display: 'inline-block', verticalAlign: 'top', float: 'left'}}>
                                                       <img src={croppedProfilePicture} style={{height: 140}} />
                                                  </div>
                                                  <div style={{display: 'inline-block', verticalAlign: 'top', marginLeft: -5, background: 'linear-gradient(to right, '+microColor+', '+microEndColor+')', height: 140, position: 'absolute', left: 140, right: 0}}>
                                                       <div style={{paddingLeft: 12, paddingTop: 5, paddingBottom: 5}}>
                                                       <h2 style={{color: '#FFF', fontSize: 16, marginTop: 0, marginBottom: 0}}>{name}</h2>
                                                       <div style={{color: '#FFF', fontSize: 13}}>
                                                            <div>{(position !== "") ? position : "Job Position"}</div>
                                                            </div>
                                                       </div>
                                                       <div style={{position:'absolute', bottom: 0, paddingLeft: 12, color: '#FFF', fontSize: 16, paddingBottom: 5}}>
                                                            <span>{(city !== "") ? city : "City Name"}</span><br /><span>{(country !== "") ? country : "Country Name"}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>

                                        <div style={{borderTop: '2px solid #FFF', height: 50, color: '#FFF', paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8, fontStyle: 'italic', background: '#8c8d8f'}}>
                                             <div style={{height: 50, fontSize: 14, overflow: 'hidden'}}>{(microIntro !== "") ? microIntro : "Namecard Introduction"}</div>
                                        </div>
                                   </div>

                                   {
                                        colorArray.map(colorItem => {
                                             if(colorItem["start"] !== "")
                                             {
                                                  return (
                                                       <div style={{display: 'inline-block', width: '20%', height: 40, textAlign: 'center', marginBottom:20}}>
                                                            <div onClick={() => {setMicroColor(colorItem["start"]); setMicroEndColor(colorItem["color"]);}} style={{background: colorItem["start"], display: 'inline-block', width: 50, height: 50, padding: 8, verticalAlign: 'top'}}>
                                                                 {(colorItem["start"] === microColor) ? <img src="./assets/icon/icon-check.png" style={{maxHeight: '100%'}} /> : "" }
                                                            </div>
                                                       </div>
                                                  )
                                             }
                                        })
                                   }

                                   <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20, paddingRight: 15}}>

                                        <IonList>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">City</IonLabel>
                                                  <IonInput value={city}  onIonChange={(e) => setCity((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Country</IonLabel>
                                                  <IonInput value={country}  onIonChange={(e) => setCountry((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 5}}>
                                                  <IonLabel position="stacked">Job Position</IonLabel>
                                                       <IonTextarea rows={4} value={position} onIonChange={(e) => setPosition((e.target as HTMLInputElement).value)}></IonTextarea>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 5}}>
                                                  <IonLabel position="stacked">Namecard Intro</IonLabel>
                                                       <IonTextarea rows={4} value={microIntro} onIonChange={(e) => setMicroIntro((e.target as HTMLInputElement).value)}></IonTextarea>
                                             </IonItem>
                                        </IonList>

                                        <IonGrid>
                                             <IonRow style={{marginTop: 20}}>

                                                  <IonCol style={{padding: 0}}>
                                                       <button onClick={() => prevSection('address')} style={{background: 'linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%)', borderColor: '#28a4c9', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}}>Prev</button>
                                                  </IonCol>
                                                  <IonCol style={{padding: 0}}>
                                                       <button style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>

                                   </div>
                              </div>

                         </div>


                    </div>

                    <IonModal isOpen={showModal} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <div style={{width: '100%', position: 'fixed', zIndex: 10, background: '#FFF'}}>
                                   <IonSearchbar value={searchTerm} onIonChange={(e) => searchLocation((e.target as HTMLInputElement).value)} placeholder="Enter a searchterm to set your location (e.g. Miami)..." ></IonSearchbar>
                              </div>

                              <IonList style={{marginTop: 60, position: 'relative', zIndex:5}}>
                                   {
                                        locations.map(locationData => {
                                             return (
                                                  <div key={locationData["data"]} onClick={() => updateLocation(locationData)}>
                                                       <IonItem style={{paddingLeft: 0, marginLeft: -10, marginRight: 10}}>
                                                            <div style={{minHeight:30, width: '100%', paddingLeft: 8, fontSize: 14}}>
                                                                 <span style={{display:'inline-block', marginBottom: 2}}><b>{locationData["value"]}</b></span>
                                                                 <div style={{float: 'right', fontSize:11, color: '#AAA', marginRight:4}}>{locationData["type"]}</div>
                                                                 <div style={{ fontSize: 11, color: '#AAA'}}>{locationData["value_sub"]}</div>
                                                            </div>
                                                       </IonItem>
                                                  </div>
                                             )
                                        })
                                   }
                              </IonList>
                         </IonContent>
                    </IonModal>

                    <IonAlert
                         isOpen={showAlert}
                         onDidDismiss={() => setShowAlert(false)}
                         cssClass='my-custom-class'
                         header={'Error'}
                         message={alertMessage}
                         buttons={['OK']}
                    />

               </IonContent>
          </IonPage>
     );
};

export default Register;
