import React from 'react';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonList, IonItem, IonLabel, IonFooter } from '@ionic/react';
import './SearchLocation.css';

const SearchLocation: React.FC = () => {
     const [searchTerm, setSearchTerm] = React.useState('');
     const [locations, setLocations] = React.useState([]);
     const [timerIsSet, setTimerIsSet] = React.useState(false);
     //const [timer, setTimer] = React.useState({});

     let timer = setTimeout(function(){
          console.log('timer is running');
     }, 1000*60*10);

     function searchLocation(searchValue: string)
     {
          setSearchTerm(searchValue);

          clearTimeout(timer);
          console.log('cleared - '+searchValue);

          timer = setTimeout(function()
          {
               setTimerIsSet(true);
               return axios(
               {
                    url: 'https://api.penewo.com/api/search-location?term='+searchValue,
                    method: 'get'
               }).then(response => {
                    console.log(response);

                    setLocations(response.data);

               });
          }, 2500);
     }

     return (
          <IonPage>
               <IonHeader>
                    <IonToolbar>
                         <div style={{marginTop: 70, paddingTop: 10}}>
                              <IonSearchbar value={searchTerm} onIonChange={(e) => searchLocation((e.target as HTMLInputElement).value)}  placeholder="search by location..."></IonSearchbar>
                         </div>
                    </IonToolbar>
               </IonHeader>
               <IonContent>

                    <IonList>
                         {
                              locations.map(locationData => {
                                   return (
                                        <a href={"search/result/all/"+locationData["value_raw"]} style={{textDecoration: 'none'}}>
                                             <IonItem key={locationData["data"]} style={{paddingLeft: 0, marginLeft: -10, marginRight: 10}}>
                                                  <div style={{minHeight:30, width: '100%', paddingLeft: 8, fontSize: 14}}>
                                                       <span style={{display:'inline-block', marginBottom: 2}}><b>{locationData["value"]}</b></span>
                                                       <div style={{float: 'right', fontSize:11, color: '#AAA', marginRight:4}}>{locationData["type"]}</div>
                                                       <div style={{ fontSize: 11, color: '#AAA'}}>{locationData["value_sub"]}</div>
                                                  </div>
                                             </IonItem>
                                        </a>
                                   )
                              })
                         }
                    </IonList>

               </IonContent>
          </IonPage>
     );
};

export default SearchLocation;
