import React from 'react';
import axios from 'axios';
import { IonContent, IonToggle, IonInput, IonLabel, IonTextarea, IonList, IonItem, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditIncognito.css';

const EditIncognito: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [showName, setShowName] = React.useState(false);
     const [showPhoto, setShowPhoto] = React.useState(false);
     const [showContact, setShowContact] = React.useState(false);
     const [showAddress, setShowAddress] = React.useState(false);
     const [showMap, setShowMap] = React.useState(false);
     const [showAboutMe, setShowAboutMe] = React.useState(false);

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     console.log(privateUserId);

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setShowName(data[0]["show_name"]);
               setShowPhoto(data[0]["show_avatar"]);
               setShowContact(data[0]["show_contact"]);
               setShowAddress(data[0]["show_address"]);
               setShowMap(data[0]["show_map"]);
               setShowAboutMe(data[0]["show_about_me"]);
          });
     }, []);

     function saveData(itemLabel:any, itemValue:any)
     {
          //setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('label', itemLabel);
               bodyFormData.append('value', itemValue);

               console.log(itemValue);


               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/incognito',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);


               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Incognito Mode</h2>

                         <div style={{paddingLeft: 15, paddingRight: 15}}>
                              <p style={{fontWeight: 'bold', fontSize: 12}}>Set which profile information users that are not your friends can see.</p>
                         </div>
                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20, paddingRight: 15}}>

                              <IonList>

                                   <IonItem style={{marginLeft: -10, marginRight: -10, paddingLeft:0, paddingRight:0}}>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 10, fontSize: 14, fontStyle: 'italic'}}>
                                                       Show Name:
                                                  </IonCol>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 3, textAlign: 'right'}}>
                                                       <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginRight: 5}}>No</span> <IonToggle color="success" checked={showName} onIonChange={e => saveData("show_name", e.detail.checked)} /> <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginLeft: 5}}>Yes</span>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -10, marginRight: -10, paddingLeft:0, paddingRight:0}}>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 10, fontSize: 14, fontStyle: 'italic'}}>
                                                       Show Profile Picture:
                                                  </IonCol>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 3, textAlign: 'right'}}>
                                                       <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginRight: 5}}>No</span> <IonToggle color="success" checked={showPhoto} onIonChange={e => saveData("show_avatar", e.detail.checked)} /> <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginLeft: 5}}>Yes</span>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -10, marginRight: -10, paddingLeft:0, paddingRight:0}}>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 10, fontSize: 14, fontStyle: 'italic'}}>
                                                       Show Contact Info:
                                                  </IonCol>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 3, textAlign: 'right'}}>
                                                       <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginRight: 5}}>No</span> <IonToggle color="success" checked={showContact} onIonChange={e => saveData("show_contact", e.detail.checked)} /> <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginLeft: 5}}>Yes</span>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -10, marginRight: -10, paddingLeft:0, paddingRight:0}}>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 10, fontSize: 14, fontStyle: 'italic'}}>
                                                       Show Address:
                                                  </IonCol>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 3, textAlign: 'right'}}>
                                                       <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginRight: 5}}>No</span> <IonToggle color="success" checked={showAddress} onIonChange={e => saveData("show_address", e.detail.checked)} /> <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginLeft: 5}}>Yes</span>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -10, marginRight: -10, paddingLeft:0, paddingRight:0}}>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 10, fontSize: 14, fontStyle: 'italic'}}>
                                                       Show Map:
                                                  </IonCol>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 3, textAlign: 'right'}}>
                                                       <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginRight: 5}}>No</span> <IonToggle color="success" checked={showMap} onIonChange={e => saveData("show_map", e.detail.checked)} /> <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginLeft: 5}}>Yes</span>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -10, marginRight: -10, paddingLeft:0, paddingRight:0}}>
                                        <IonGrid>
                                             <IonRow>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 10, fontSize: 14, fontStyle: 'italic'}}>
                                                       Show About Me:
                                                  </IonCol>
                                                  <IonCol style={{background: '#F6F6F6', height: 38, paddingTop: 3, textAlign: 'right'}}>
                                                       <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginRight: 5}}>No</span> <IonToggle color="success" checked={showAboutMe} onIonChange={e => saveData("show_about_me", e.detail.checked)} /> <span style={{verticalAlign: 'top', display: 'inline-block', paddingTop: 7, marginLeft: 5}}>Yes</span>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>
                                   </IonItem>

                              </IonList>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditIncognito;
