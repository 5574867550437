import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonTextarea, IonList, IonItem, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditAboutMe.css';

const EditAboutMe: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [name, setName] = React.useState('');
     const [quote, setQuote] = React.useState('');
     const [intro, setIntro] = React.useState('');

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     console.log(privateUserId);

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setName(data[0]["name"]);
               setQuote(data[0]["quote"]);
               setIntro(data[0]["description"]);
          });
     }, []);

     function saveData()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('name', name);
               bodyFormData.append('quote', quote);
               bodyFormData.append('intro', intro);

               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/about-me',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit About Me</h2>
                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20, paddingRight: 15}}>

                              <IonList>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Name</IonLabel>
                                        <IonInput value={name} onIonChange={(e) => setName((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Favourite Quote</IonLabel>
                                        <IonInput value={quote}  onIonChange={(e) => setQuote((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 5}}>
                                        <IonLabel position="stacked">Intro</IonLabel>
                                             <IonTextarea rows={4} value={intro} onIonChange={(e) => setIntro((e.target as HTMLInputElement).value)}></IonTextarea>
                                   </IonItem>
                              </IonList>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => saveData()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditAboutMe;
