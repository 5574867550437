import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditContact.css';

const EditContact: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [email, setEmail] = React.useState('');
     const [phone, setPhone] = React.useState('');
     const [website, setWebsite] = React.useState('');
     const [facebook, setFacebook] = React.useState('');
     const [twitter, setTwitter] = React.useState('');
     const [linkedin, setLinkedin] = React.useState('');

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     const fetchUserData = () => {

          return axios(
          {
               url: 'https://api.penewo.com/api/return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setEmail(data[0]["email"]);
               setPhone(data[0]["phone"]);
               setWebsite(data[0]["website"]);
               setFacebook(data[0]["facebook"]);
               setTwitter(data[0]["twitter"]);
               setLinkedin(data[0]["linkedin"]);
          });
     }, []);

     function saveData()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('email', email);
               bodyFormData.append('phone', phone);
               bodyFormData.append('website', website);
               bodyFormData.append('facebook', facebook);
               bodyFormData.append('twitter', twitter);
               bodyFormData.append('linkedin', linkedin);

               return axios(
               {
                    url: 'https://api.penewo.com/api/save-user-data/contact',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Contact Info</h2>
                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20, paddingRight: 15}}>

                              <IonList>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Email</IonLabel>
                                        <IonInput value={email} onIonChange={(e) => setEmail((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Phone</IonLabel>
                                        <IonInput value={phone}  onIonChange={(e) => setPhone((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Website</IonLabel>
                                        <IonInput value={website}  onIonChange={(e) => setWebsite((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Facebook</IonLabel>
                                        <IonInput value={facebook}  onIonChange={(e) => setFacebook((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">Twitter</IonLabel>
                                        <IonInput value={twitter}  onIonChange={(e) => setTwitter((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                        <IonLabel position="stacked">LinkedIn</IonLabel>
                                        <IonInput value={linkedin}  onIonChange={(e) => setLinkedin((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>
                              </IonList>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => saveData()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditContact;
